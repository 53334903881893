import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import InputPanel from './input_panel/InputPanel'
import KeywordInput from './keyword_input/KeywordInput';
import { nextStage, previousStage } from '../../store/stage_store'


import { stages, stageCount } from '../../common/stage'


import './FeatureInput.css';

function FeatureInput() {
    const dispatch = useDispatch()

    function goNext() {
        dispatch(nextStage());
    }

    function goBack() {
        dispatch(previousStage());
    }

    const stageIdx = useAppSelector((state) => state.stage.value)

    const inputComponent = () => {
        if (stages[stageIdx].key === 'keywords') {
            return <KeywordInput stageIndex={stageIdx} goNext={goNext} goBack={stageIdx > 0 ? goBack : undefined}></KeywordInput>
        } else {
            return (
                <InputPanel stageIndex={stageIdx} goNext={goNext} goBack={stageIdx > 0 ? goBack : undefined}></InputPanel >
            )
        }
    }


    return (
        <div className="feature-input" >
            {inputComponent()}
        </div >
    );
}

export default FeatureInput;
