import { FeatureState } from '../store/feature_store'
import { Configuration, OpenAIApi } from "openai"
import { Gender } from './enums'

const OPENAI_API_KEY = "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"

const configuration = new Configuration({
    apiKey: OPENAI_API_KEY,
});

const openai = new OpenAIApi(configuration);

export function getOpenAiSuggestions(features: FeatureState): Promise<Array<[string, string]>> {
    return getOpenAiResponse(features).then((content) => {
        let items = content.split('\n')
        return items.filter((item) => item.trim().length > 0).map((item) => [item.split('##')[0].slice(1), item.split('##')[1]])
    });
}

async function getOpenAiResponse(features: FeatureState): Promise<string> {
    const prompt = buildPrompt(features)
    const response = openai.createCompletion({
        model: "text-davinci-003",
        prompt: prompt,
        temperature: 0,
        max_tokens: 300,
    });
    return response.then((value) => value.data.choices[0].text || '');
}

function getKeywords(features: FeatureState): string {
    let keywords = ['niche']
    if ((features.category ?? '') in ['apparel', 'jewelry']) {
        keywords.push('chic, in-style')
    } else {
        keywords.push('trendy')
    }

    if ((features.gender ?? '') == 'female') {
        keywords.push('in-style, from a good brand')
    } else {
        keywords.push('practical, cool')
    }

    if ((features.relationship ?? '') == 'romantic partner') {
        keywords.push('romantic')
    }
    return keywords.join(', ')
}

export function buildPrompt(features: FeatureState): string {
    // const prompt = `Imagine you are a <online shopping master>. You are ${features.selfGender} and you want to buy are buy a <gift> for your <${features.relationship}> for the occasion <${features.occasion}>. 
    // This person is ${features.gender} and with the age of <${features.age}>. Could you recommend <3 good quality and chic and in-trend gifts> with a <budget ${features.budget}>? 
    // Please make sure the price is in budget, also alls the can be bought on <USA internet>
    // Could you also provide the brand, description of the item, price and why you think it is good in 15 words. Put each gift info on a new line, start with a *.
    // `
    let budgetStr = 'USD$' + String(features.minPrice) + '-' + String(features.maxPrice)
    let categoryStr = features.category !== '' ? 'in the category of ' + features.category : ''

    const prompt =
        `Imagine that you are an <online shopping master>. I’d like to buy a <gift> for my <${features.gender}> <${features.relationship}> for <${features.occasion}>. 
         Could you recommend something that is <${getKeywords(features)}>, also <${features.keywords}> and good-quality for me, condition on <budget ${budgetStr}>, in category <${categoryStr}> please? 
         I’d like you to provide 4 gift recommendations, you should provide them in format <product name with brand>##<one sentence description of the item about the features in 15 words>. each line contains one gift recommendation. no extra lines. and each line start with a *.
         Please make sure the price is in <budget ${budgetStr}>, <${categoryStr}> also all of them can be bought on < USA internet >.Thank u!
        `
    console.log(prompt)
    return prompt
}  