import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { stageCount } from '../common/stage'


interface StageState {
    value: number
}

const initialState: StageState = {
    value: 0,
}


export const stageSlice = createSlice({
    name: 'stage',
    initialState,
    reducers: {
        nextStage: (state) => {
            if (state.value < stageCount) {
                state.value += 1
            }
        },
        previousStage: (state) => {
            if (state.value >= 0) {
                state.value -= 1
            }
        },
        restart: (state) => {
            state.value = 0;
        },
    },
})

// Action creators are generated for each case reducer function
export const { nextStage, previousStage, restart } = stageSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectStage = (state: RootState) => state.stage.value

export default stageSlice.reducer