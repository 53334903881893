import { useState, useEffect, useRef } from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import ShareIcon from '@mui/icons-material/Share';
import Snackbar from '@mui/material/Snackbar';
import Grow from '@mui/material/Grow';
import Carousel from 'react-material-ui-carousel'
import Subscribe from '../subscribe/Subscribe';

import ReactGA from 'react-ga4';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import './ProductDisplay.css';
import { ProductInfo } from '../../common/product';
import { Alert } from '@mui/material';

function truncateTitle(title: string) {
    return title.split(',')[0].split(' - ')[0]
}

interface ProductCardProps {
    propductInfo: ProductInfo
}
function ProductCard(props: ProductCardProps) {
    const productInfo = props.propductInfo;
    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', maxWidth: 250, height: 470, borderRadius: '10px', overflow: 'visible', boxShadow: 3 }}>
            <CardMedia
                component="img"
                alt="product image"
                height="200"
                width="200"
                image={productInfo.img}
                sx={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
            />
            <CardContent className='product-card-content'>
                <div className="product-card-name">{truncateTitle(productInfo.title)}</div>
                <div className='product-card-price-seller'>
                    <div className="product-card-price">{productInfo.price}</div>
                    -
                    <div className="product-card-seller">{productInfo.seller}</div>
                </div>
                <div className="product-card-description">{productInfo.description}</div>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center', mb: '5px' }}>
                <a href={productInfo.link} target="_blank" rel="noreferrer">
                    <Button
                        sx={{ bgcolor: '#ff887d' }}
                        size="small"
                        variant="contained"
                        startIcon={<CardGiftcardIcon />}
                        onClick={() => ReactGA.event({
                            category: 'Product',
                            action: 'Visit product link',
                            label: productInfo.link,
                        })}
                    >
                        Buy now
                    </Button>
                </a>
            </CardActions>
        </Card >
    );
}

function ProductCards(productInfos: Array<ProductInfo>, windowWidth: number) {
    if (windowWidth > 600) {
        return (<Grid container className='product-list' spacing={2}>
            {productInfos.map((propductInfo, i) =>
                <Grow in={true} timeout={500 + 1000 * i}>
                    <Grid key={i} item>
                        <ProductCard propductInfo={propductInfo} />
                    </Grid>
                </Grow>)}
        </Grid>)
    } else {
        return (
            <Carousel animation='slide' navButtonsAlwaysInvisible indicators autoPlay={false} className='product-carousel'>
                {
                    productInfos.map((propductInfo, i) =>
                        <Grid key={i} item>
                            <ProductCard propductInfo={propductInfo} />
                        </Grid>)
                }
            </ Carousel>
        )
    }
}

function RatingComponent(rating: number, setRating: any, setOpenSubModal: any, openSubModal: number) {
    return (
        <Grid item xs={12} className='review'>
            <Grow in={true} timeout={4000}>
                <div className='review-title'>How do you like our recommendation?</div>
            </Grow>
            <Grow in={true} timeout={4000}>
                <div className='review-rating'>
                    <Rating size="large"
                        icon={<StarIcon fontSize="inherit" color='primary' />}
                        defaultValue={0}
                        value={rating}
                        onChange={(event, newValue) => {
                            if (newValue != null && newValue > 0) {
                                setRating(newValue ?? 0); ReactGA.event({
                                    category: 'Product',
                                    action: 'Rating',
                                    value: newValue ?? 0,
                                })
                            }
                            setOpenSubModal(openSubModal === 0 ? 1 : openSubModal);
                        }} />
                </div>
            </Grow>
            {rating > 0 && (<div className='review-footer'>Thanks for your response! ❤️</div>)}
        </Grid>
    )
}

interface ProductDisplayProps {
    productInfos: Array<ProductInfo>
    onRestartClick: any
}
function ProductDisplay(props: ProductDisplayProps) {
    const [windowWidth, windowHeight] = useRef([window.innerWidth, window.innerHeight]).current;

    const [rating, setRating] = useState(0);
    const [openShareAlert, setopenShareAlert] = useState(false);
    // 0 - never open, 1 - opening, 2 - opened, should not open again 
    const [openSubModal, setOpenSubModal] = useState(0);



    ReactGA.send({ hitType: "pageview", page: 'result/' });

    function onShareClick() {
        navigator.clipboard.writeText('https://product.giftinai.com/?utm_source=share');
        setopenShareAlert(true);
        ReactGA.event({ category: 'Product', action: 'Share' });
        setTimeout(() => setopenShareAlert(false), 4000);
    }
    return (
        <div className='product-display-root'>
            <Grid className='product-display' direction="column">
                <Collapse in={openShareAlert}>
                    <Snackbar
                        open={openShareAlert}
                        autoHideDuration={4000}
                    >
                        <Alert severity="success" sx={{ width: '100%' }}>
                            Share link copied, share it with your friends!
                        </Alert>
                    </Snackbar>
                </Collapse>

                <Grid item xs={12}>
                    <Grow in={true}>
                        <div className='product-display-title'>Check these out!</div>
                    </Grow>
                </Grid>
                <Grid container >
                    {ProductCards(props.productInfos, windowWidth)}
                </Grid >
                <Grow in={true}>
                    <Grid item className='control-panel'>
                        <IconButton size='large'
                            onClick={onShareClick}
                            sx={{ color: 'white', border: '1px solid white', borderRadius: '10px', padding: '6px', margin: '3px' }}>
                            <ShareIcon />
                        </IconButton>
                        <IconButton size='large'
                            onClick={props.onRestartClick}
                            sx={{ color: 'white', border: '1px solid white', borderRadius: '10px', padding: '6px', margin: '3px' }}>
                            <RestartAltIcon />
                        </IconButton>
                    </Grid>
                </Grow>
                {RatingComponent(rating, setRating, setOpenSubModal, openSubModal)}
            </Grid>
            <Subscribe open={openSubModal === 1} onClose={() => setOpenSubModal(2)}></Subscribe>
        </div>
    )

}

export default ProductDisplay;
