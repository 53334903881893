import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useAppSelector, useAppDispatch } from './store/hooks'
import { stageCount } from './common/stage'
import { getOpenAiSuggestions } from './common/openapi';
import { restart } from './store/stage_store'


import FeatureInput from './componet/feature_input/FeatureInput';
import ProductDisplay from './componet/product_display/ProductDisplay';
import Loading from './componet/loading/Loading';
import { fetchGoogleShoppingProductInfo } from './common/fetchProductInfo';
import './App.css';


async function getProductInfo(items: Array<[String, String]>, minPrice: number, maxPrice: number) {
    let productInfos = await fetchGoogleShoppingProductInfo(items.map((item: [String, String]) => item[0]), minPrice, maxPrice);
    //let productInfos = testProductInfos;

    for (let i = 0; i < items.length; i++) {
        if (productInfos[i] !== null && productInfos[i] !== undefined) {
            productInfos[i]['description'] = items[i][1];
        }
    }
    return productInfos.filter((item: any) => item !== null);
}

function App() {
    const dispatch = useDispatch()

    const [products, setProducts] = useState([]);
    const [hasError, setError] = useState(false);

    const stage_idx = useAppSelector((state) => state.stage.value)
    const features = useAppSelector((state) => state.feature)
    //const displayResult = true;
    const displayResult = stage_idx === stageCount;
    useEffect(() => {
        const loadAiRecommendation = async () => {
            setError(false);

            //console.log(features)
            let giftIdeas = await getOpenAiSuggestions(features);
            //let giftIdeas = testGiftIdeas;

            //console.log(giftIdeas);
            let products = await getProductInfo(giftIdeas, features.minPrice, features.maxPrice);
            //console.log(products);
            if (products.length === 0) {
                setError(true);
            }
            setProducts(products);
        }
        if (displayResult) {
            loadAiRecommendation();
        }
    }, [displayResult])


    function elements() {
        if (hasError) {
            return <div className='loading'>Error! No data is returned</div>
        }

        if (!displayResult) {
            return <FeatureInput />
        }
        if (products.length === 0) {
            return <Loading />
        }
        return <ProductDisplay productInfos={products} onRestartClick={() => { setProducts([]); dispatch(restart()); }}></ProductDisplay>
    }
    return (<div className='app'>
        {
            elements()
        }
    </div >
    );
}

export default App;

var testProductInfos: any = [
    {
        "img": "https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcSffSZYA0fR3wbUN2T4i_J4TpTwyuyw8AqNPj-DdOVB0et9H_tthpTm0M5dPAkOClHFLcXVa6miIRMohzTNoW3JazP9GIU1LECpEz5MzAG6vOM9bJ_XLv5E&usqp=CAE",
        "link": "https://www.ebay.com/itm/285131733688",
        "price": "$14.89",
        "seller": "eBay - triplenetpricing",
        "title": "Philips Norelco Mg3760 Multigroom Series 3000 Trimmer, Grey"
    },
    {
        "img": "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcSW5svLh4pGVQi8sLN3izOaW6VaOgX0K4rYpxUlNnxjB7mi1hecm9S1r1lf-SmujldZW-mgQzmMoSzMfWKUoC_SoLRfEYGsCQbOICIIs7Q&usqp=CAE",
        "link": "https://www.amazon.com/Timex-Weekender-Chrono-Analog-Quartz-Leather/dp/B01JO766FW",
        "price": "$69.99",
        "seller": "Amazon.com",
        "title": "Timex Men's Weekender Chronograph 40mm Leather Strap -Tan- Watch TWC063500"
    },
    {
        "img": "https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcS3cB0qqYOwl2NdDTzCQass0OG6BbItjN_IEwA1hAdQlkxaLLm19R_0bXHeArifsKs5OJz1q-w&usqp=CAE",
        "link": "https://www.bose.com/en_us/products/speakers/portable_speakers/soundlink-micro.html%23v%3Dsoundlink_micro_midnight_blue&rct=j&q=&esrc=s&sa=U&ved=0ahUKEwis24TD1YD_AhXuFFkFHTTIClsQguUECI8O&usg=AOvVaw0PT1GjUA5h7dQsfa5FUHmi",
        "price": "$99.00",
        "seller": "Bose",
        "title": "SoundLink Micro Portable Bluetooth Smart Speaker - Siri Supported - Dark Blue"
    },
    {
        "img": "https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcQN-XpUiayfwk8ssjT0sWuF2IKgSPphyBaV7SLYACF82XbTE_RRfFIleNaAF9W2kRK5ZhC8PsA&usqp=CAE",
        "link": "https://www.amazon.com/Tile-Mate-2-Pack-Discontinued-Manufacturer/dp/B08BNVQDWT",
        "price": "$44.95",
        "seller": "Amazon.com - Seller",
        "title": "Tile Mate (2020) 2-Pack -Bluetooth Tracker, Keys"
    },
    null
]

let testGiftIdeas: Array<[string, string]> = [
    [
        "Philips Norelco Multigroom Series 3000",
        "This all-in-one trimmer is perfect for your male relatives, with 13 attachments for trimming and styling facial hair and a budget-friendly price."
    ],
    [
        "Timex Weekender Chronograph Watch",
        "This classic watch is perfect for any occasion, with a timeless design and a budget-friendly price."
    ],
    [
        "Bose SoundLink Micro Bluetooth Speaker",
        "This portable speaker is perfect for any occasion, with a waterproof design and a budget-friendly price."
    ],
    [
        "Tile Mate Bluetooth Tracker",
        "This Bluetooth tracker is perfect for any occasion, with a slim design and a budget-friendly price."
    ],
    [
        "AmazonBasics 6-Piece Dinnerware Set",
        "This dinnerware set is perfect for any occasion, with a classic design and a budget-friendly price."
    ]
];