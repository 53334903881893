import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../store/hooks'

import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import './Loading.css';

function loadingWords(progress: number) {
    if (progress < 30) {
        return 'Analyzing your request...';
    } else if (progress < 60) {
        return 'Searching for gift ideas...';
    } else {
        return 'Generating gift links...';
    }
}

function Loading() {
    const [progress, setProgress] = useState(10);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 5));
        }, 600);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box className='loading-box'>
            <div className='padding-dummy'></div>
            <div className='spinner'>
                <CircularProgress color='inherit' variant="determinate" value={progress} size='200px' thickness={1} />
            </div>
            <Box
                className='icon-box'

            >
                <img src='/icon.png' className='icon' />
            </Box>
            <div className='loading-words'>
                {loadingWords(progress)}
            </div>
        </Box>);
}

export default Loading;
