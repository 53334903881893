import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import { useSelector, useDispatch } from 'react-redux'
import ReactGA from 'react-ga4';

import Grow from '@mui/material/Grow';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


import { delay } from '../../../common/util';
import { Payload } from '../../../common/payload';
import { editFeature } from '../../../store/feature_store'
import { stages, stageCount } from '../../../common/stage'
import { KeywordChipData, KeywordChipDataSet, keywordChipDataByCategory } from '../../../common/keywords'
import InputBox from '../input_box/InputBox';
import './KeywordInput.css';

interface KeywordInputProps {
    stageIndex: number
    goNext: () => void
    goBack?: () => void
}


function KeywordInput(props: KeywordInputProps) {
    const dispatch = useDispatch()

    const stage = stages[props.stageIndex]
    const [loaded, setLoaded] = useState(false);
    const [selectedChipData, setSelectedChipData] = useState(new Array<KeywordChipData>());

    const stageIdx = useAppSelector((state) => state.stage.value)

    useEffect(() => {
        setLoaded(false);
        async function lateLoad() {
            await delay(100);
            setLoaded(true);
        }

        lateLoad();
    }, [props])

    const handleDelete = (keyword: string) => {
        setSelectedChipData(selectedChipData.filter((chipData) => chipData.label !== keyword))
    }


    async function onSubmit() {
        let selectedKeywords = selectedChipData.filter((chipData) => chipData.type === 'vibes').map((chipData) => chipData.value).join(',');
        let selectedCategories = selectedChipData.filter((chipData) => chipData.type === 'category').map((chipData) => chipData.value).join(' or ');

        ReactGA.send({ hitType: "pageview", page: 'feature-input/' + stage.key });
        ReactGA.event({
            category: 'Feature Input',
            action: stage.key,
            label: selectedKeywords,
        });
        let featurePayload: Payload[] = [
            { 'key': 'keywords', 'value': selectedKeywords },
            { 'key': 'category', 'value': selectedCategories }
        ]

        dispatch(editFeature(featurePayload));
        setTimeout(() => {
            props.goNext();
        }, 1000);
    }


    return (
        <div className='persona-input-root'>
            <Grow in={loaded} timeout={1000}>
                <div className='title'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems='center'>
                        <IconButton
                            aria-label="back"
                            sx={props.goBack === undefined ? { 'display': "none" } : { 'color': 'white', 'pr': '5px' }}
                            onClick={props.goBack}
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                        <div className={`step-number ${props.goBack === undefined ? "" : "right-padding"}`} >
                            {props.stageIndex + 1} / {stageCount}
                        </div>
                    </Grid>
                    <div>{stage.title}</div>
                </div>
            </Grow >
            <Grow in={loaded} {...(loaded ? { timeout: 2000 } : {})}>
                <div>
                    <div className='selected-persona'>
                        {
                            selectedChipData.map((keywordChipData: KeywordChipData) => {
                                return (
                                    keywordChipData.emoji === '' ?
                                        <Chip
                                            label={keywordChipData.label}
                                            onClick={() => handleDelete(keywordChipData.label)}
                                            onDelete={() => handleDelete(keywordChipData.label)}
                                            sx={{
                                                color: 'white',
                                                border: "white 1px solid",
                                                backgroundColor: 'rgba(0,0,0,0)',
                                                margin: '2px'
                                            }} /> :
                                        <Chip
                                            label={keywordChipData.label}
                                            icon={<div className='chip-emoji'>{keywordChipData.emoji}</div>}
                                            onClick={() => handleDelete(keywordChipData.label)}
                                            onDelete={() => handleDelete(keywordChipData.label)}
                                            sx={{
                                                color: 'white',
                                                border: "white 1px solid",
                                                backgroundColor: 'rgba(0,0,0,0)',
                                                margin: '2px'
                                            }} />
                                )
                            })
                        }
                    </div>
                    <div className='input-panel'>
                        <div className='selection-panel'>
                            {
                                keywordChipDataByCategory.map((keywordChipDataSet: KeywordChipDataSet) => {
                                    return (
                                        <div className='selection-category'>
                                            <div className='selection-category-desc'>{keywordChipDataSet.description}</div>
                                            <div>{keywordChipDataSet.keywordChipDatas?.map((keywordChipData: KeywordChipData) => (
                                                <Chip
                                                    onClick={() => setSelectedChipData([...selectedChipData, keywordChipData])}
                                                    label={keywordChipData.label}
                                                    disabled={selectedChipData.includes(keywordChipData)}
                                                    icon={<div className='chip-emoji'>{keywordChipData.emoji}</div>}
                                                    sx={{
                                                        color: 'white',
                                                        border: "white 1px solid",
                                                        backgroundColor: 'rgba(0,0,0,0)',
                                                        margin: '2px'
                                                    }} />
                                            ))
                                            }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='selection-category-desc'> Or something specific? Write it down!</div>
                    <div className='custom-persona-input'>
                        <InputBox onSubmit={(v) => setSelectedChipData([...selectedChipData, { label: v, value: v, emoji: '', type: 'vibe' }])} />
                    </div>
                    <div className='submit-persona-button'>
                        <Button
                            size='large'
                            variant="outlined"
                            onClick={onSubmit}
                            sx={{ color: 'white', borderColor: 'white', "&:hover": { borderColor: 'white' } }}>
                            {selectedChipData.length === 0 ? 'Surprise Me!' : 'Next'}
                        </Button>
                    </div>
                </div>
            </Grow>
        </div >
    );
}

export default KeywordInput;