import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';



import './InputBox.css';

interface InputBoxProps {
    onSubmit: (value: string) => void
}
function InputBox(props: InputBoxProps) {
    const [value, setValue] = useState('');

    const onTextChange = (e: any) => {
        setValue(e.target.value);
    }

    const onSubmit = () => {
        if (value === '') {
            return;
        }
        props.onSubmit(value);
        setValue('');
    }
    return (
        <div>
            <TextField
                id="standard-multiline-flexible"
                hiddenLabel
                placeholder="Tap ↵ to add keywords"
                variant="standard"
                margin="none"
                className='persona-input'
                value={value}
                inputProps={{
                    maxLength: 25,
                    style: { color: "white", fontFamily: 'Montserrat', fontSize: '1rem' },
                }}
                onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                        onSubmit();
                    }
                }}
                onChange={onTextChange}
            />
            <Button
                sx={{ color: 'white', borderColor: 'white', "&:hover": { borderColor: 'white' } }}
                disabled={value === ''}
                onClick={onSubmit}>+Add</Button>
        </div>
    );
}

export default InputBox;
