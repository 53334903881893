import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Gender, Relationship, Occasion } from '../common/enums'
import { Payload } from '../common/payload'
import type { RootState } from './store'

export interface FeatureState {
    relationship: String,
    gender: String,
    age: string,
    occasion: Occasion,
    minPrice: number,
    maxPrice: number,
    category?: string,
    keywords?: string,
    [key: string]: any
}

// Whether we already gather enough infomation to submit for recommendation.
export const canSubmit = (state: RootState) => state.feature.selfGender !== Gender.None &&
    state.feature.relationship !== '' &&
    state.feature.gender !== '' &&
    state.feature.age !== '' &&
    (state.feature.minPrice !== 0 || state.feature.maxPrice !== 0) &&
    state.feature.occasion !== Occasion.None;


const initialState: FeatureState = {
    relationship: '',
    gender: '',
    age: '',
    minPrice: 0,
    maxPrice: 0,
    keywords: '',
    occasion: Occasion.None,
    category: '',
}

// Store of the features which gathered from user and used to as input features to OpenAI API.
export const featureSlice = createSlice({
    name: 'feature',
    initialState,
    reducers: {
        editFeature: (state, action: PayloadAction<Array<Payload>>) => {
            for (let payload of action.payload) {
                state[payload.key] = payload.value
            }
        },
    },
})


// Action creators are generated for each case reducer function
export const { editFeature } = featureSlice.actions

export default featureSlice.reducer