import axios from 'axios';
import axiosRetry from 'axios-retry';

export async function fetchGoogleShoppingProductInfo(items, min_price, max_price) {
    const params = { "items": items.join(','), "min_price": min_price, "max_price": max_price }
    const url = 'https://gifts-backend-386905.wl.r.appspot.com/productinfo'

    try {
        const instance = axios.create({
            baseURL: url,
            params: params,
            timeout: 15000,
        });

        axiosRetry(instance, { retries: 2 });

        const response = await instance.get('');
        return response.data.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
// export async function fetchGoogleShoppingProductInfo(query) {
//     console.log('start')
//     const JSSoup = require('jssoup').default;


//     const headers = {
//         "User-Agent":
//             "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_5) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.1 Safari/605.1.15"
//     }
//     const params = { "q": query, "hl": "en", 'gl': 'us', 'tbm': 'shop' }
//     const url = 'https://www.google.com/search'

//     const instance = axios.create({
//         baseURL: url,
//         params: params,
//         timeout: 1000,
//         headers: headers
//     });

//     const response = await instance.get('');

//     // Save html to file for testing
//     // var fs = require('fs');
//     // fs.writeFile("test.html", response.data, function (err) {
//     //     if (err) {
//     //         console.log(err);
//     //     }
//     // });

//     let soup = new JSSoup(response.data);


//     let items = soup.findAll('div', 'sh-dgr__content');
//     let item = items[0];

//     let title = item.find('h3', 'tAxDx').text;
//     let price = item.find('span', 'OFFNJ').text;
//     let seller = item.find('div', 'IuHnof').text.split('}').slice(-1)[0];
//     let img = item.find('div', 'm7bmjc').find('img').attrs['data-image-src'];
//     let link = item.find('a', 'shntl').attrs.href.slice(9).split('%3F')[0];
//     return {
//         title: title,
//         price: price,
//         seller: seller,
//         img: img,
//         link: link
//     }
//     // console.log(title);
//     // console.log(price);
//     // console.log(seller);
//     // console.log(img);
//     // console.log(link);
// }

// //Test
// (async () => { await fetchGoogleShoppingProductInfo('gucci belt'); })();


// Used for Ads, the link does not works since google has a hoop
// var items = soup.findAll('a', 'sh-np__click-target');

// items.forEach(item => {
//     let title = item.find('h3', 'sh-np__product-title').text;
//     let price = item.find('b').text;
//     let seller = item.find('span', 'E5ocAb').text;
//     let img = item.find('img').attrs.src;
//     let link = item.attrs.href;
//     console.log(title, price, seller, img, "https://www.google.com" + link);
// });