import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { Payload } from '../../../common/payload';
import { delay } from '../../../common/util';
import { editFeature } from '../../../store/feature_store'
import { SingleSelectStage, stages, stageCount } from '../../../common/stage'
import ReactGA from "react-ga4";

import './InputPanel.css';

interface InputPanelProps {
    stageIndex: number
    goNext: () => void
    goBack?: () => void
}

function InputPanel(props: InputPanelProps) {

    async function onSingleSelectionClick(e: any) {
        ReactGA.send({ hitType: "pageview", page: 'feature-input/' + stage.key });
        ReactGA.event({
            category: 'Feature Input',
            action: stage.key,
            label: e.currentTarget.value,
        });
        let featureValues = JSON.parse(e.currentTarget.value)
        let featurePayload: Payload[] = Object.keys(featureValues).map((key) => { return { 'key': key, 'value': featureValues[key] } });
        dispatch(editFeature(featurePayload));
        setTimeout(() => {
            props.goNext();
        }, 1000);
    }

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
        async function lateLoad() {
            await delay(1000);
            setLoaded(true);
        }

        lateLoad();
    }, [props])

    const dispatch = useDispatch()
    const stage = stages[props.stageIndex]
    return (
        <div className="input-panel" hidden={!loaded}>
            <Grow in={loaded} timeout={1000}>
                <div className='title'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems='center'>
                        <IconButton
                            aria-label="back"
                            sx={props.goBack === undefined ? { 'display': "none" } : { 'color': 'white', 'pr': '5px' }}
                            onClick={props.goBack}
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                        <div className={`step-number ${props.goBack === undefined ? "" : "right-padding"}`} >
                            {props.stageIndex + 1} / {stageCount}
                        </div>
                    </Grid>
                    <div>{stage.title}</div>
                </div>
            </Grow >
            <Grow in={loaded} {...(loaded ? { timeout: 2000 } : {})}>

                <Grid className='button-panel' container justifyContent="center" spacing={3} >
                    {
                        (stage instanceof SingleSelectStage) && stage.selections.map((buttonInfo, i) =>
                            <Grid key={i} item>
                                <Button
                                    size='large'
                                    variant="outlined"
                                    onClick={onSingleSelectionClick}
                                    value={JSON.stringify(buttonInfo.value)}
                                    sx={{ color: 'white', borderColor: 'white', "&:hover": { borderColor: 'white' } }}>
                                    {buttonInfo.name}
                                </Button>
                            </Grid>)
                    }
                </Grid>
            </Grow>

        </div >
    );
}

export default InputPanel;