export interface KeywordChipData {
    label: string,
    value: string,
    emoji: string,
    type: string,
}

export interface KeywordChipDataSet {
    name: string,
    description: string,
    isSingleSelection: boolean,
    keywordChipDatas: Array<KeywordChipData>,
}

export const keywordChipDataByCategory: Array<KeywordChipDataSet> = [
    {
        name: 'category',
        description: 'Any specfic category in your mind?',
        isSingleSelection: true,
        keywordChipDatas: [
            { label: 'Tech', value: 'technology products', emoji: '📱', type: 'category' },
            { label: 'Beauty', value: 'beauty products including makeup, skincare and perfume', emoji: '💄', type: 'category' },
            { label: 'Jewelry', value: 'Jewelry', emoji: '💍', type: 'category' },
            { label: 'Sports', value: 'sports product', emoji: '🏀', type: 'category' },
            { label: 'Delicious Treats', value: 'food and beverages', emoji: '🍫', type: 'category' },
            { label: 'Cooking & Baking ', value: 'cooking and baking stuff', emoji: '🍳', type: 'category' },
            { label: 'Home decor', value: 'home decoration', emoji: '🛋', type: 'category' },
        ]
    },
    {
        name: 'keywords',
        description: 'The Vibes...',
        isSingleSelection: false,
        keywordChipDatas: [
            { label: 'Branded', value: 'big-brand', emoji: '✅', type: 'vibe' },
            { label: 'Niche', value: 'niche', emoji: '🔮', type: 'vibe' },
            { label: 'Artsy', value: 'artsy', emoji: '🎨', type: 'vibe' },
            { label: 'Vintage', value: 'vintage', emoji: '🕰️', type: 'vibe' },
            { label: 'High-end', value: 'high-end', emoji: '🌟', type: 'vibe' },
        ]
    },
];

// new SingleSelectStage('category', 'Last thing, about this gift, any vibes in your mind already?', '', [

//     { name: 'Tech products', value: { category: 'technology products' } },
//     { name: 'Makeup', value: { category: 'makeup products' } },
//     { name: 'Jewelry', value: { category: 'jewelry' } },
//     { name: 'Clothing', value: { category: 'apparel' } },
//     { name: 'Chic decorations', value: { category: 'Chic home decorations' } },
//     { name: 'Sports', value: { category: 'sports products' } },
//     { name: 'Artsy crafts', value: { category: 'arts and crafts products' } },
//     { name: 'Delicious Treats', value: { category: 'food and beverages like chocolate, wine, coffee, or cheese' } },
//     { name: 'Suprise Me!', value: { category: '' } },
// ]),]

// export const keywordChipDataByCategory: Map<string, Array<KeywordChipData>> = new Map([
//     ['This person is a...', [
//         { label: 'Tech Geek', value: 'tech geek', emoji: '🧑‍💻' },
//         { label: 'Tennis Master', value: 'love play tennis', emoji: '🎾' },
//         { label: 'Artist', value: 'have a good taste of art', emoji: '🧑‍🎨' },
//     ]],
//     ['This person loves...', [
//         { label: 'Video Game', value: 'play video games', emoji: '🎮️' },
//         { label: 'Camping', value: 'go camping', emoji: '🏕️' },
//         { label: 'Nightclub', value: 'go to night club', emoji: '🪩' },
//         { label: 'Vintage Hunt', value: 'find vintage', emoji: '🗿' },
//     ]],
//     ['This person WOW people by...', [
//         { label: 'Photography', value: 'love take photos', emoji: '📸' },
//         { label: 'Handcrafts', value: 'love to do handy work', emoji: '🪚' },
//         { label: 'Kpop Dance', value: 'love K pop dancing', emoji: '💃' },
//     ]],
// ]);