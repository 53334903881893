import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { doc, setDoc } from '@firebase/firestore'
import { subscriberCol } from '../../common/backend';
import './Subscribe.css';

function ValidateEmail(email: string) {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return (expression.test(String(email).toLowerCase()))
}

interface SubscribeProps {
    open: boolean,
    onClose: () => void
}
function Subscribe(props: SubscribeProps) {
    const [value, setValue] = useState('');

    const onTextChange = (e: any) => {
        setValue(e.target.value);
    }

    const onSubmit = async () => {
        if (ValidateEmail(value) === false) {
            return;
        }
        try {
            const subscriberRef = doc(subscriberCol, value);
            await setDoc(subscriberRef, { timestamp: Date.now() });
        } catch (e) {
        }
        props.onClose();
    }
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Subscribe</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Subscribe to our newsletter to get updates on our latest changes!
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="standard"
                    value={value}
                    onChange={onTextChange}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}>Cancel</Button>
                <Button
                    disabled={ValidateEmail(value) === false}
                    onClick={onSubmit} > Subscribe </Button>
            </DialogActions>
        </Dialog>
    );
}

export default Subscribe;
