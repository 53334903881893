export enum Gender {
    None = 0,
    Male = 1,
    Female = 2,
}

export enum Relationship {
    None = 0,
    Couple = 1,
    Friend = 2,
    Relative = 3,
}

export enum Occasion {
    None = 0,
    Birthday = 1,
    Anniversary = 2,
    Farewell = 3,
    FirstMet = 4,
}