export interface ButtonInfo {
    name: string,
    value: Object // An stringfied Obeject of feature key to value
}

// A section of question and its corresponding selections
export interface InfoSection {
    description: string,
    selections: Array<ButtonInfo>,
    isMultiSelection: Boolean,
    isOptional?: Boolean
}

export class Stage {
    key: string; // The id of the stages
    title: string;
    description: string;

    constructor (key: string, title: string, description: string) {
        this.key = key;
        this.title = title;
        this.description = description;
    }
}

export class SingleSelectStage extends Stage {
    selections: Array<ButtonInfo>;

    constructor (key: string, title: string, description: string, selections: Array<ButtonInfo>) {
        super(key, title, description)
        this.selections = selections;
    }
}

export class MultiSelectStage extends Stage {
    infoSections: Array<InfoSection>;
    constructor (key: string, title: string, description: string, infoSections: Array<InfoSection>) {
        super(key, title, description)
        this.key = key;
        this.title = title;
        this.description = description;
        this.infoSections = infoSections;
    }
}



// Stage in order
export const stages = [
    new SingleSelectStage('relationship', 'Hi, welcome to Gift\'In, your AI gift agent. Who would you like to send a gift to?', '', [
        { name: 'Romantic Partner', value: { relationship: 'romantic partner' } },
        { name: 'Friend', value: { relationship: 'friend' } },
        { name: 'Kids', value: { relationship: 'kids' } },
        { name: 'Coworker', value: { relationship: 'coworker' } },
        { name: 'Siblings', value: { relationship: 'sibling' } },
        { name: 'Elders', value: { relationship: 'elder' } },
        { name: 'Just Anyone ;)', value: { relationship: 'random person' } }
    ]),
    new SingleSelectStage('gender', 'What\'s the gender of that person?', '', [
        { name: 'Male', value: { gender: 'male' } },
        { name: 'Female', value: { gender: 'female' } },
        { name: 'Other', value: { gender: '' } }
    ]),
    new SingleSelectStage('occasion', 'Is there a special occasion for this gift?', '', [
        { name: '🎂 Birthday', value: { occasion: 'birthday' } },
        { name: '💝 Anniversary', value: { occasion: 'anniversary' } },
        { name: '🌹 Valentine\'s', value: { occasion: 'Valentine\'s day' } },
        { name: '👰 Weddings', value: { occasion: 'Weddings' } },
        { name: '🏡 Housewarming', value: { occasion: 'housewarming' } },
        { name: '👼 Baby showers', value: { occasion: 'baby showers' } },
        { name: '🙏 Thank you', value: { occasion: 'thank you' } },
        { name: '👋 Farewell', value: { occasion: 'farewell' } },
    ]),
    new SingleSelectStage('budget', 'This is important! What is your budget?', '', [
        { name: 'Under 30', value: { minPrice: 0, maxPrice: 30 } },
        { name: '$30 - $100', value: { minPrice: 30, maxPrice: 100 } },
        { name: '$100 - $300', value: { minPrice: 100, maxPrice: 300 } },
        { name: 'Above $300', value: { minPrice: 300, maxPrice: 1000 } },
    ]),
    new Stage('keywords', 'About your perfect gift...', ''),
    // new MultiSelectStage('identity', 'Alright, to better find the right gift could you select the chracteristic of the person?', '', [
    //     {
    //         description: 'What is the age of this person?', selections: [
    //             { name: 'Youth', value: 'youth' },
    //             { name: 'Teenager', value: 'teenager' },
    //             { name: 'Young Adult', value: 'young adult' },
    //             { name: 'Middle Age', value: 'middle age' },
    //             { name: 'Elder', value: 'elder' },
    //         ], isMultiSelection: true,
    //     },
    //     {
    //         description: 'What is the age of this person?', selections: [
    //             { name: 'Youth', value: 'youth' },
    //             { name: 'Teenager', value: 'teenager' },
    //             { name: 'Young Adult', value: 'young adult' },
    //             { name: 'Middle Age', value: 'middle age' },
    //             { name: 'Elder', value: 'elder' },
    //         ], isMultiSelection: true,
    //     },
    // ]),
    // new SingleSelectStage('category', 'Last thing, about this gift, any vibes in your mind already?', '', [

    //     { name: 'Tech products', value: { category: 'technology products' } },
    //     { name: 'Makeup', value: { category: 'makeup products' } },
    //     { name: 'Jewelry', value: { category: 'jewelry' } },
    //     { name: 'Clothing', value: { category: 'apparel' } },
    //     { name: 'Chic decorations', value: { category: 'Chic home decorations' } },
    //     { name: 'Sports', value: { category: 'sports products' } },
    //     { name: 'Artsy crafts', value: { category: 'arts and crafts products' } },
    //     { name: 'Delicious Treats', value: { category: 'food and beverages like chocolate, wine, coffee, or cheese' } },
    //     { name: 'Suprise Me!', value: { category: '' } },
    // ]),
]

export const stageCount = stages.length;
