// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, DocumentData, CollectionReference } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAsAGQVsMWoIeCL2IhCIY8QWUlmMT8aDPI",
  authDomain: "gifts-backend-386905.firebaseapp.com",
  projectId: "gifts-backend-386905",
  storageBucket: "gifts-backend-386905.appspot.com",
  messagingSenderId: "1012915022053",
  appId: "1:1012915022053:web:00c6217293506c62fe8c0c",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Export firestore incase we need to access it directly
export const firestore = getFirestore()
// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>
}

interface Subscriber {
  timestamp: number
}
export const subscriberCol = createCollection<Subscriber>('subscribers')
